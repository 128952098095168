import React, { useEffect, useState } from "react";
import { server } from "../../config/server.js";
import ViewCount from "../../components/ViewCount.js";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import logistikMain from "../../assets/logistikMain.png";

export default function Pengumuman() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [album, setAlbum] = useState([]);
  const [header, setHeader] = useState({});
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [headerResponse, albumResponse] = await Promise.all([
        fetch(`${server}/api/v1/content/list/44/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
        fetch(`${server}/api/v1/content/detail/4/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ]);

      const [headerResult, albumResult] = await Promise.all([
        headerResponse.json(),
        albumResponse.json(),
      ]);

      if (headerResult.status) {
        setHeader(headerResult.data[0] || {});
      } else {
        throw new Error("Failed to fetch header data");
      }

      if (albumResult.status) {
        const formattedAlbum = formatAlbumData(albumResult.data);
        setAlbum(formattedAlbum);
      } else {
        throw new Error("Failed to fetch album data");
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    }
  };

  const formatAlbumData = (data) => {
    return data.map((album) => ({
      ...album,
      content_files: album.content_files.map((file) => ({
        ...file,
        src: file.public_link,
      })),
    }));
  };

  const handleAlbumClick = (photos, title) => {
    galleryModal(photos, title);
  };

  const galleryModal = (photos, title) => {
    MySwal.fire({
      html: (
        <div>
          <div className="w100 py-4 modalTitle pageText" style={{ fontWeight: 600, color: "#005f3b" }}>
            {title}
          </div>
          <div className="center-vertical mx-auto text-center popupFotoContainer" style={{ minHeight: "50vh" }}>
            {photos.map((img, i) => (
              <img
                key={i}
                onClick={() => {
                  setLightboxOpen(true);
                  setSelectedImage(i);
                }}
                className="galleryImg px-2 py-2 mb-5 mx-3"
                src={img.public_link}
                alt={`Photo ${i + 1}`}
              />
            ))}
          </div>
          <button
            className="btn mx-3 px-5"
            style={{
              borderRadius: "50rem",
              fontFamily: "Roboto",
              fontSize: 18,
              border: "1px solid #005F3B",
              background: "#005F3B",
              color: "white",
            }}
            onClick={() => MySwal.close()}
          >
            Back
          </button>
        </div>
      ),
      width: "fit-content",
      height: "fit-content",
      customClass: "galleryModal",
      allowOutsideClick: false,
      showClass: {
        backdrop: "swal2-noanimation",
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
      showConfirmButton: false,
      heightAuto: false,
    });
  };

  const backgroundImage = header.content_header_link ? header.content_header_link.replace(/ /g, "%20") : null;
  const contentTitle = header.content_title || "";
  const contentViews = header.views || "0";

  return (
    <>
      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        slides={album[selectedImage]?.content_files || []}
        plugins={[Thumbnails]}
        index={selectedImage}
      />
      <img class="w100" style={{ borderRadius: 20 }} src={backgroundImage}></img>
      <div className="pageTitle">{contentTitle}</div>
      <div className="articleContainer pageText">
        <div className="list image">
          <div className="w100 fotoCardContainer">
            {album.map((photos, index) => (
              <div
                key={index}
                className="fotoCard mx-3 mb-4"
                onClick={() => handleAlbumClick(photos.content_files, photos.content_title)}
              >
                <div
                  className="albumThumbnail"
                  style={{
                    backgroundImage: `url("${photos.content_files[0]?.public_link || ""}")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    cursor: "pointer",
                    backgroundColor: "transparent",
                  }}
                ></div>
                <div className="cardName greenText center-vertical pageText greenText">{photos.content_title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ViewCount count={contentViews} />
    </>
  );
}
